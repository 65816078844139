import fs from 'fs'
import path from 'path'
import Cookies from 'js-cookie'

const [COOKIE_KEY, COOKIE, COOKIE_BTN, VISIBLE_CLASSNAME] = [
    'COOKIE_AGREEMENT',
    '#__cookie',
    '#__cookie-btn',
    'cookie-bar--visible',
]

try {
    const data = fs.readFileSync(path.join(__dirname, 'style.module.scss'), 'utf8')
    const container = document.querySelector(COOKIE)
    const btn = document.querySelector(COOKIE_BTN)
    if (!container) throw `Missing ${COOKIE} element`
    if (!btn) throw `Missing ${COOKIE_BTN} element`

    let style = document.createElement('style')
    container.appendChild(style)
    style.textContent = data

    if (Cookies.get(COOKIE_KEY) === 'true') return container.remove()

    container.classList.add('cookie-bar--visible')
    btn.addEventListener('click', () => {
        Cookies.set(COOKIE_KEY, 'true', { expires: 7 })
        container.classList.remove(VISIBLE_CLASSNAME)
    })
} catch (error) {
    console.error(error)
}
